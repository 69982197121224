export default {
    feed: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        maxWidth: '1200px',
        margin: '20px 0 5vh 0',
        '@media (max-width: 767px)': {
            padding: '0',
            marginBottom: '0',
        }
    },
    data: {
        topText: `At PerfectCreditMatch.com our goal is to help you 
        find the perfect credit card for your needs FAST! 
        Finding the right credit card with the right benefits will provide financial ease of mind. 
        Pay your balance every month? A rewards card may offer more points or cash back. 
        Carry balances every month? Find a card with the lowest interest rates! More questions? 
        Scroll down to read more about your credit and comparing cards. 
        Ready to go? Click the "Get Started" button above to select the card type you are interested in 
        to find your credit card match - no email or personal information required! It's just that easy.`
    },
    overrideFeatured: {
        featuredTitle: {
            marginRight: '25px',
            fontSize: '3rem',
            fontFamily: 'Roboto'
        },
        itemTitle: {
            fontFamily: 'Roboto'
        }
    },
    overrideKnowledge: {
        contentTitleSpan: { color: 'rgb(1, 158, 71)', fontFamily: 'Roboto' },
        typeColumnTitle: { color: '#d667cd', fontFamily: 'Roboto' },
        columnItemTitle: { }
    },
    overrideEditorial: {
        contentTitleSpan: { color: 'rgb(1, 158, 71)', fontFamily: 'Roboto' },
        editorialTitle: { color: 'rgb(20, 107, 180)', textAlign: 'left', fontSize: '3rem', fontFamily: 'Roboto' },
        articleTitle: { fontFamily: 'Roboto' }
    },
    overrideLatest: {
        contentTitleSpan: { color: 'rgb(1, 158, 71)', fontFamily: 'Roboto' },
        articleTitle: { fontFamily: 'Roboto' }
    },
    overridePopular: {
        contentTitleSpan: { color: 'rgb(1, 158, 71)', fontFamily: 'Roboto' },
        cardTitle: { fontFamily: 'Roboto'}
    },
}
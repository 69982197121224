export default [
	{
        icon: ['left', 'fal', 'credit-card'],
        text: 'Credit Cards',
        color: 'blueish',
        value: 'credit_cards'
    },
    {
        icon: ['left', 'fal', 'hand-holding-usd'],
        text: 'Personal Loans',
        color: 'green',
        value: 'personal_loans'
    },
    {
        icon: ['left', 'fal', 'home-heart'],
        text: 'Home Loans',
        color: 'purple',
        value: 'home_loans'
    },
    {
        icon: ['left', 'fal', 'car'],
        text: 'Auto Loans',
        color: 'orange',
        value: 'auto_loans'
	},
];
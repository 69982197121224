import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../context';
import Feed from './Feed';
import Navbar from '@bit/bluekeel.component-library.navbar';
import Footer from '@bit/bluekeel.component-library.footer';
import LoadingBubbles from '@bit/bluekeel.component-library.loading-bubbles';
import SideDrawer from './SideDrawer';
import Routes from '../Routes';
import { useHistory } from 'react-router-dom';
import PcmIconLogo from '@bit/bluekeel.assets.pcm_icon_logo';
import PcmFullLogo from '@bit/bluekeel.assets.pcm_full_logo';
import { getCookie } from '@bit/bluekeel.controllers.helpers';
import UseSetNewSession from '@bit/bluekeel.controllers.use-set-new-session';
import Radium from 'radium';
import Styles from './Styles.css.js';

const App = () => {
	let history = useHistory();
	const [ myURL ] = useState(new URL(window.location.href));
	const [ showDrawer, toggleDrawer ] = useState(false);
	const [ showLoading, setLoading ] = useState(true);
	const [ animationComplete ] = useState(true);
	const { appState, dispatchApp, dispatchTracking } = useContext(AppContext);
	
	const handleMenuClick = () => {
		toggleDrawer(!showDrawer);
	};

	const goHome = () => {
        dispatchApp({ type: 'RESTART_SEARCH' });
        window.scrollTo(0, 0);
		history.push('/');
	};

	const tracking = {
        HSID: myURL.searchParams.get('hsid') || 0,
        PID: myURL.searchParams.get('pid') || getCookie('pid') || 3251,
        SID: myURL.searchParams.get('sid') || getCookie('sid') || 9305,
        OID: myURL.searchParams.get('oid') || getCookie('oid') || 40,
        UID: myURL.searchParams.get('uid') || getCookie('uid') || null,
        EID: myURL.searchParams.get('eid') || getCookie('eid') || 'organic',
        SE: myURL.searchParams.get('se') || getCookie('se') || null,
        KWD: myURL.searchParams.get('kwd') || getCookie('kwd') || null,
        PACID: myURL.searchParams.get('pacid') || getCookie('pacid') || null,
        PT1: myURL.searchParams.get('pt1') || getCookie('pt1') || 'N/A',
        PT2: myURL.searchParams.get('pt2') || getCookie('pt2') || 'N/A',
        GCLID: myURL.searchParams.get('gclid') || getCookie('gclid') || null,
        EMAIL: myURL.searchParams.get('email') || getCookie('email') || '',
        VERTICAL: myURL.searchParams.get('vertical') || 'N/A',
        TYPE: myURL.searchParams.get('type') || 'N/A',
		AUTH_GROUP: myURL.searchParams.get('group') || 'bk'
    };

	useEffect(() => {
		if(tracking.AUTH_GROUP !== 'bk') {
			dispatchTracking({ type: 'SET_GROUP', payload: tracking.AUTH_GROUP });
		};
		// eslint-disable-next-line
	}, [tracking]);

	const redirectTo = UseSetNewSession({ 
		tracking, dispatchTracking, dispatchApp, 
		setLoading, animationComplete 
    });
    
    useEffect(() => {
        if(!!redirectTo && !showLoading) {
            history.push(redirectTo);
        };
        // eslint-disable-next-line
	}, [redirectTo, showLoading]);
	
	if(showLoading) {
		return (
			<div style={Styles.loadingContainer}>
				<LoadingBubbles />
			</div>
		)
	};

	return (
		<div key='pcm-app' style={Styles.app}>	
			<Navbar
				key='pcm-navbar'
				drawerClick={handleMenuClick} 
				goHome={goHome}
				brand={appState['showFullLogo'] ? PcmFullLogo : PcmIconLogo}
				styleVariant={navbarVariants}
			> 
				<Routes />
				<Feed />
				<Footer key='pcm-footer' domain='PerfectCreditMatch' logo={PcmIconLogo}/>
				<SideDrawer show={showDrawer} toggle={toggleDrawer}/>
			</Navbar>
		</div>
	);
};

const navbarVariants = {
	navbar: {},
	toolbar: {},
	navContent: {}, 
	brand: {}, 
	// brand: { maxHeight: isMobile ? '40px' : '50px' }, 
	menuIcon: {}
};

export default Radium(App);
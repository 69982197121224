import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const items = [
    { key: 'card-animation-item__0', color: 'rgb(20, 107, 180)', icon: 'credit-card' },
    { key: 'card-animation-item__1', color: 'rgb(1, 158, 71)', icon: 'car' },
    { key: 'card-animation-item__2', color: 'rgb(20, 107, 180)', icon: 'home-heart' },
    { key: 'card-animation-item__3', color: 'rgb(1, 158, 71)', icon: 'search-dollar' }
];

const IconDeck = () => {
    

    return (
        <div className='card-animation__container'>
            {items.map((item) => (
                <div
                    key={item.key}
                    className={`animation-item__container ${item.key}`}
                >
                <FontAwesomeIcon
                    icon={['fal', item.icon]}
                    style={{ color: item.color}}
                    className='animation-icon'
                />
            </div>
            ))}
        </div>
    );
}

export default IconDeck;